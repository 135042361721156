import { clipCursorToRectangle, ClipRectangle, usePixiMouseEventListener } from "@poscon/shared-frontend";
import { Coordinate, Nullable } from "@poscon/shared-types";
import { UnlistenFn } from "@tauri-apps/api/event";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import React, { SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";

const cursors = ["crosshair", "pointer"] as const;
type StarsCursor = typeof cursors[number];

const cursorOffsetMap: Record<StarsCursor, Coordinate> = {
  crosshair: [7, 7],
  pointer: [0, 0],
}

type ClipCursorContextValue = {
  setCursor: React.Dispatch<SetStateAction<Nullable<StarsCursor>>>;
  setClipRectangle: React.Dispatch<SetStateAction<Nullable<ClipRectangle>>>;
  setClipButtonRectangle: React.Dispatch<SetStateAction<Nullable<ClipRectangle>>>;
};
export const ClipCursorContext = React.createContext<ClipCursorContextValue | null>(null);

type ClipCursorContextProviderProps = {
  children: React.ReactNode;
};
export const ClipCursorContextProvider = ({ children }: ClipCursorContextProviderProps) => {
  const [cursor, setCursor] = useState<Nullable<StarsCursor>>("crosshair");
  const [clipRectangle, setClipRectangle] = useState<ClipRectangle | null>(null);
  const [clipButtonRectangle, setClipButtonRectangle] = useState<ClipRectangle | null>(null);
  const rectRef = useRef(clipButtonRectangle ?? clipRectangle);
  rectRef.current = clipButtonRectangle ?? clipRectangle;

  useLayoutEffect(() => {
    if (cursor === null) {
      document.body.style.cursor = "none";
    } else {
      const offset = cursorOffsetMap[cursor];
      document.body.style.cursor = `url(/cursors/${cursor}.png) ${offset[0]} ${offset[1]}, auto`;
    }
  }, [cursor]);

  usePixiMouseEventListener(() => {
    if (cursor !== null && document.body.style.cursor === "none") {
      const offset = cursorOffsetMap[cursor];
      document.body.style.cursor = `url(/cursors/${cursor}.png) ${offset[0]} ${offset[1]}, auto`;
    }
  }, undefined, undefined, "mousemove");

  useEffect(() => {
    let unlisten: { fn?: UnlistenFn } = {};
    if (window.__TAURI__) {
      WebviewWindow.getCurrent().onFocusChanged(({ payload: focused }) => {
        if (focused && rectRef.current) {
          void clipCursorToRectangle(rectRef.current);
        }
      }).then((fn) => {
        unlisten.fn = fn;
      });
    }
    return () => {
      unlisten.fn?.();
    }
  }, []);

  useLayoutEffect(() => {
    if (window.__TAURI__) {
      const rect = clipButtonRectangle ?? clipRectangle;
      if (rect) {
        void clipCursorToRectangle(rect);
      } else {
        WebviewWindow.getCurrent().setCursorGrab(false);
      }
    }
  }, [clipButtonRectangle, clipRectangle]);

  return <ClipCursorContext.Provider value={{ setClipRectangle, setClipButtonRectangle, setCursor }}>{children}</ClipCursorContext.Provider>;
};

export const useClipCursorContext = () => {
  const contextValue = React.useContext(ClipCursorContext);
  if (contextValue === null) {
    throw new Error("useClipCursorContext must be used within a ClipCursorContextProvider");
  }
  return contextValue;
};