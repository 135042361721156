import React from "react";
import { Container, Graphics, BitmapText } from "@pixi/react";
import { Coordinate } from "@poscon/shared-types";
import { fontName, fontDim } from "~/constants";
import { useRootSelector } from "~/redux/hooks";
import { selectBrightButtonValue } from "~/redux/slices/starsSlice";
import { colorNameMap, computeColor } from "@poscon/shared-frontend";
import { layerZIndexMap } from "~/layerZIndexMap";
import { tcwColors } from "~/theme";

const strokeLen = 15;
const step = Math.PI / 36;

type CompassProps = {
  x?: number;
  y?: number;
  width: number;
  height: number;
};

export const Compass = ({ x = 0, y = 0, width, height }: CompassProps) => {
  const bright = useRootSelector((state) => selectBrightButtonValue(state, "CMP"));
  const center = [width / 2, height / 2] satisfies Coordinate;
  const r = Math.sqrt(width ** 2 + height ** 2) / 2;
  const maxAngle = Math.acos(height / (2 * r));
  const tint = computeColor(tcwColors.compassRose, bright / 100, 0);

  return bright === 0 ? null : (
    <Container x={x + center[0]} y={y + center[1]} zIndex={layerZIndexMap.compass} eventMode="none">
      <Graphics draw={(graphics) => {
        graphics.clear();
        graphics.lineStyle(1, tint);
        graphics.drawRect(-center[0] + 1, -center[1], width - 1, height - 1).endFill();
        for (let i = 0; i < 19; i++) {
          const angle = step * i;
          const len = angle < maxAngle ? height / (2 * Math.cos(angle)) : width / (2 * Math.cos(Math.PI / 2 - angle));
          const endLen = len - strokeLen;
          const dir = [Math.sin(angle), Math.cos(angle)] satisfies Coordinate;
          const start = [len * dir[0], len * dir[1]] satisfies Coordinate;
          const end = [endLen * dir[0], endLen * dir[1]] satisfies Coordinate;
          graphics.moveTo(start[0], start[1])
            .lineTo(end[0], end[1])
            .endFill();
          graphics.moveTo(-start[0], -start[1])
            .lineTo(-end[0], -end[1])
            .endFill();
          if (i % 9 !== 0) {
            graphics.moveTo(start[0], -start[1])
              .lineTo(end[0], -end[1])
              .endFill();
            graphics.moveTo(-start[0], start[1])
              .lineTo(-end[0], end[1])
              .endFill();
          }
        }
      }}
      />
      {Array.from({ length: 10 }, (_, i) => {
        const angle = 2 * step * i;
        const len = angle < maxAngle ? height / (2 * Math.cos(angle)) - (strokeLen + fontDim.height) : width / (2 * Math.cos(Math.PI / 2 - angle)) - (strokeLen + fontDim.width * 2)
        const pos = [len * Math.sin(angle), len * Math.cos(angle)] satisfies Coordinate;
        return (
          <React.Fragment key={i}>
            <BitmapText anchor={[0.5, 0.5]} x={pos[0]} y={-pos[1]} text={(i * 10).toString().padStart(3, "0")} tint={tint} style={{ fontName, tint }} />
            <BitmapText anchor={[0.5, 0.5]} x={-pos[0]} y={pos[1]} text={(i * 10 + 180).toString().padStart(3, "0")} tint={tint} style={{ fontName, tint }} />
            {i % 9 !== 0 && <>
              <BitmapText anchor={[0.5, 0.5]} x={pos[0]} y={pos[1]} text={(180 - i * 10).toString().padStart(3, "0")} tint={tint} style={{ fontName, tint }} />
              <BitmapText anchor={[0.5, 0.5]} x={-pos[0]} y={-pos[1]} text={(360 - i * 10).toString().padStart(3, "0")} tint={tint} style={{ fontName, tint }} />
            </>}
          </React.Fragment>
        );
      })}
    </Container>
  );
};
