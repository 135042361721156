import { Graphics } from "@pixi/react";
import { Graphics as PixiGraphics } from "pixi.js";
import { geoPath } from "d3-geo";
import { FeatureCollection, Geometry } from "geojson";
import { useProjection } from "~/contexts/sdContext";
import { colorNameMap, computeColor } from "@poscon/shared-frontend";
import { useRootSelector } from "~/redux/hooks";
import {
  selectGeomapByStarsId,
  selectGeomapFeatures,
  selectOpenMaps,
} from "~/redux/slices/starsTempSlice";
import {
  selectBrightButtonValue,
} from "~/redux/slices/starsSlice";
import { useCallback } from "react";
import { defaultLineStyle } from "~/constants";
import { tcwColors } from "~/theme";

export type GeomapGeometryContainerProps = {
  featureCollection: FeatureCollection<Geometry>;
  alpha: number;
  tint?: Uint8Array;
};
export const GeomapGeometryContainer = ({
  featureCollection,
  alpha,
  tint = colorNameMap.white,
}: GeomapGeometryContainerProps) => {
  const projection = useProjection();

  const draw = useCallback((graphics: PixiGraphics) => {
    graphics.clear();
    graphics.lineTextureStyle();
    graphics.lineStyle({
      ...defaultLineStyle,
      color: computeColor(tint, alpha, 0),
    });
    const pathGen = geoPath(projection).context(graphics as any);
    for (const feature of featureCollection.features) {
      pathGen(feature.geometry);
    }
  }, [featureCollection, alpha, tint, projection]);

  return <Graphics draw={draw} zIndex={alpha * 100} />;
};

type GeomapProps = {
  starsId: number;
};
const Geomap = ({ starsId }: GeomapProps) => {
  const geomapData = useRootSelector((state) =>
    selectGeomapByStarsId(state, starsId)
  );
  const brightCategory = geomapData?.starsBrightnessCategory === "B"
    ? "MPB"
    : "MPA";
  const featureCollection = useRootSelector((state) => geomapData ? selectGeomapFeatures(state, geomapData.id) : null);
  const bright = useRootSelector((state) =>
    selectBrightButtonValue(state, brightCategory)
  );

  return featureCollection
    ? (
      <GeomapGeometryContainer
        featureCollection={featureCollection}
        alpha={bright / 100}
        tint={brightCategory === "MPA"
          ? tcwColors.catAMaps
          : tcwColors.catBMaps}
      />
    )
    : null;
};

export const Geomaps = () => {
  const openMaps = useRootSelector(selectOpenMaps);

  return (
    <>
      {openMaps.map((starsMapId) => {
        return <Geomap key={starsMapId} starsId={starsMapId} />;
      })}
    </>
  );
};
