import {
  ConflictPair,
  EramFlightplan,
  FlightplanId,
  PartialFpWithId,
  RequireKeys,
  StarsCoordination,
  StarsQuicklookedSectorTrack,
  StarsSectorTrack,
  StarsTrack,
  TrackId,
} from "@poscon/shared-types";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type StarsAircraftState = {
  tracks: Record<TrackId, StarsTrack>;
  sectorTracks: Record<TrackId, StarsSectorTrack>;
  quicklookedTracks: Record<TrackId, StarsQuicklookedSectorTrack>;
  flightplans: Record<FlightplanId, EramFlightplan>;
  coordinationData: Record<TrackId, StarsCoordination>;
  stcaPairs: ConflictPair[];
  suppressedConflictPairs: ConflictPair[];
};

const initialState: StarsAircraftState = {
  flightplans: {},
  tracks: {},
  sectorTracks: {},
  quicklookedTracks: {},
  coordinationData: {},
  stcaPairs: [],
  suppressedConflictPairs: [],
};

const emptyObject = {};

export const aircraftSlice = createSlice({
  name: "aircraft",
  initialState,
  reducers: {
    resetAircraftState() {
      return initialState;
    },
    deleteFlightplan(state, action: PayloadAction<FlightplanId>) {
      delete state.flightplans[action.payload];
    },
    setFlightplans(state, action: PayloadAction<EramFlightplan[]>) {
      for (const fp of action.payload) {
        state.flightplans[fp.id] = fp;
      }
    },
    setFlightplan(state, action: PayloadAction<EramFlightplan>) {
      state.flightplans[action.payload.id] = action.payload;
    },
    updateFlightplans(state, action: PayloadAction<PartialFpWithId[]>) {
      for (const { id, ...fields } of action.payload) {
        if (id in state.flightplans) {
          Object.assign(state.flightplans[id]!, fields);
        }
      }
    },
    setStcaPairs(state, action: PayloadAction<ConflictPair[]>) {
      state.stcaPairs = action.payload;
    },
    updateCoordination(
      state,
      action: PayloadAction<Record<TrackId, StarsCoordination>>,
    ) {
      for (const [id, data] of Object.entries(action.payload)) {
        state.coordinationData[id] = data;
      }
    },
    setAllTracks(state, action: PayloadAction<Record<TrackId, StarsTrack>>) {
      state.tracks = action.payload;
    },
    setTracks(state, action: PayloadAction<StarsTrack[]>) {
      for (const track of action.payload) {
        const interpolatedTrack = state.tracks[track.id]?.interpolatedTrack;
        const interpolatedHistories = state.tracks[track.id]?.interpolatedHistories;
        state.tracks[track.id] = { ...track, interpolatedTrack, interpolatedHistories };
      }
    },
    updateInterpolatedTracks(state, action: PayloadAction<Pick<StarsTrack, "interpolatedTrack" | "interpolatedHistories" | "id">[]>) {
      for (const { id, interpolatedTrack, interpolatedHistories } of action.payload) {
        if (state.tracks[id]) {
          state.tracks[id]!.interpolatedTrack = interpolatedTrack;
          state.tracks[id]!.interpolatedHistories = interpolatedHistories;
        }
      }
    },
    setTrack(state, action: PayloadAction<StarsTrack>) {
      state.tracks[action.payload.id] = action.payload;
    },
    updateTracks(
      state,
      action: PayloadAction<RequireKeys<Partial<StarsTrack>, "id">[]>,
    ) {
      for (const track of action.payload) {
        if (track.id in state.tracks) {
          Object.assign(state.tracks[track.id]!, track);
        }
      }
    },
    updateTrack(
      state,
      action: PayloadAction<RequireKeys<Partial<StarsTrack>, "id">>,
    ) {
      const track = action.payload;
      if (!state.tracks[track.id]) {
        return;
      }
      Object.assign(state.tracks[track.id]!, track);
    },
    deleteFlightplans(state, action: PayloadAction<FlightplanId[]>) {
      for (const id of action.payload) {
        delete state.flightplans[id];
      }
    },
    deleteTracks(state, action: PayloadAction<TrackId[]>) {
      for (const id of action.payload) {
        delete state.tracks[id];
        delete state.sectorTracks[id];
      }
    },
    deleteSectorTracks(state, action: PayloadAction<TrackId[]>) {
      for (const trackId of action.payload) {
        delete state.sectorTracks[trackId];
      }
    },
    setSectorTracks(state, action: PayloadAction<StarsSectorTrack[]>) {
      for (const track of action.payload) {
        state.sectorTracks[track.trackId] = track;
      }
    },
    setQuicklookTracks(
      state,
      action: PayloadAction<StarsQuicklookedSectorTrack[]>,
    ) {
      for (const track of action.payload) {
        state.quicklookedTracks[track.trackId] = track;
      }
    },
    deleteQuicklookTracks(state, action: PayloadAction<TrackId[]>) {
      for (const trackId of action.payload) {
        delete state.quicklookedTracks[trackId];
      }
    },
    setSuppressedConflictPairs(state, action: PayloadAction<ConflictPair[]>) {
      state.suppressedConflictPairs = action.payload;
    },
  },
  selectors: {
    selectTrack: (state, trackId: TrackId) => state.tracks[trackId] ?? null,
    selectSectorTrack: (state, trackId: TrackId) => state.sectorTracks[trackId],
    selectQuicklookedTrack: (state, trackId: TrackId) =>
      state.quicklookedTracks[trackId],
    selectTrackCoordination: (state, trackId: TrackId) =>
      state.coordinationData[trackId] ?? emptyObject,
    selectFlightplan: (state, fpId: FlightplanId) =>
      state.flightplans[fpId] ?? null,
  },
});

export const aircraftReducer = aircraftSlice.reducer;

export const {
  setTracks,
  setTrack,
  setAllTracks,
  updateInterpolatedTracks,
  setFlightplan,
  setFlightplans,
  setSectorTracks,
  setQuicklookTracks,
  updateTrack,
  updateTracks,
  updateFlightplans,
  updateCoordination,
  deleteTracks,
  deleteSectorTracks,
  deleteFlightplan,
  deleteFlightplans,
  deleteQuicklookTracks,
} = aircraftSlice.actions;

export const {
  selectTrack,
  selectQuicklookedTrack,
  selectTrackCoordination,
  selectFlightplan,
} = aircraftSlice.getSelectors((state: RootState) => state.aircraft);

export const selectSectorTrack = createSelector([
  (state: RootState) => state,
  (state: RootState, trackId: TrackId) => trackId,
], (state, trackId) => {
  return state.aircraft.sectorTracks[trackId] ?? new StarsSectorTrack(trackId);
});
