import { RootStore } from "./store";
import { injectStore as injectSdStore } from "../situationDisplayStore";
import { injectStore as injectTrackStore } from "../trackManager";
import { injectStore as injectHubStore } from "../starsHubConnection";

export function injectStore(store: RootStore) {
  injectSdStore(store);
  injectHubStore(store);
  injectTrackStore(store);
}
