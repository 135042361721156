import { buttonList1K, dcb1KButtonPropsMap } from "./buttons1K";
import { buttonList2K, ButtonMapValue, dcb2KButtonPropsMap } from "./buttons";
import { selectButtonSelected } from "./redux/slices/starsSlice";
import { selectIsActiveScrollButton } from "./redux/slices/starsTempSlice";
import { RootState } from "./redux/store";

export const buttonList = Array.from(new Set(...buttonList1K, ...buttonList2K))

export type ButtonId = (typeof buttonList)[number];

export const actualSelectButtonSelected = (
  state: RootState,
  buttonId: ButtonId,
  hasOnWheel: boolean,
) => {
  const selector = hasOnWheel
    ? selectIsActiveScrollButton
    : selectButtonSelected;

  return selector(state, buttonId);
};

export const dcbButtonPropsMap: Partial<Record<ButtonId, ButtonMapValue>> = {
  ...dcb1KButtonPropsMap,
  ...dcb2KButtonPropsMap,
};
