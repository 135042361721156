import { BitmapText, Container, Graphics } from "@pixi/react";
import { defaultLineStyle, starsFontNameMap } from "~/constants";
import { useRootSelector } from "~/redux/hooks";
import { selectCharSizeButtonValue, selectViewPosition } from "~/redux/slices/starsSlice";
import { colorNameMap, useCurrentTime } from "@poscon/shared-frontend";
import { formatUtcTime } from "@poscon/shared-types";
import { ColorSource } from "pixi.js";

type TimeProps = {
  x?: number;
  y?: number;
  fontName: string;
  tint: ColorSource;
};
const Time = ({ x = 0, y = 0, fontName, tint }: TimeProps) => {
  const time = useCurrentTime();

  // TODO: add system altimeter
  const text = formatUtcTime(time) + "/" + time.getUTCSeconds().toString().padStart(2, "0") + " ";

  return <BitmapText x={x} y={y} text={text} fontName={fontName} tint={tint} style={{ fontName, tint }} />;
}

export const SystemStatusArea = () => {
  const fontSize = useRootSelector((state) => selectCharSizeButtonValue(state, "CHAR_SIZE_LISTS"));
  const [x, y] = useRootSelector((state) => selectViewPosition(state, "SYSTEM_STATUS"));
  const tint = colorNameMap.green;

  const fontName = starsFontNameMap[fontSize];

  return <Container x={x} y={y} eventMode="none">
    <Graphics eventMode="none" draw={(graphics) => {
      graphics.clear();
      graphics.lineStyle(1, tint);
      graphics.drawRect(0, 0, 15, 15).endFill();
      graphics.lineStyle();
      graphics.beginFill(colorNameMap.red)
        .drawPolygon(0, 3, 14, 3, 7, 13, 0, 3)
        .endFill();
    }} />
    <Time y={18} fontName={fontName} tint={tint} />
  </Container>
}