import { RootThunkAction, setCursorPosition } from "@poscon/shared-frontend";
import {
  getStarsCommandElements,
  hasAtLeast,
  MULTIFUNC,
  StarsCommandProcessingError,
  starsFormatError,
  starsGenericResponse,
  starsIllegalValueError,
  StarsInputElement,
} from "@poscon/shared-types";
import { dispatchResetInputEvent } from "../customEvents";
import { processMultifuncCommand } from "./processMultifunc";
import { starsHubConnection } from "~/starsHubConnection";
import {
  selectActiveScrollButton,
  selectCursorHomePosition,
  setActiveScrollButton,
  setCommandResponse,
} from "~/redux/slices/starsTempSlice";
import { getButtonLimits, selectButtonSelected } from "~/redux/slices/starsSlice";

export function processStarsCommand(
  commandElements: StarsInputElement[],
): RootThunkAction {
  return async (dispatch, getState) => {
    const command = getStarsCommandElements(commandElements);
    if (!hasAtLeast(command, 1) || (command.length === 1 && !(command[0].token || command[0].targetFpId))) {
      dispatchResetInputEvent();
      return;
    }
    const state = getState();
    const activeScrollButton = selectActiveScrollButton(state);
    if (activeScrollButton) {
      if (
        command.length !== 1 || !command[0]?.token ||
        !/^\d+(\.\d+)?$/.test(command[0].token)
      ) {
        dispatch(setCommandResponse(starsFormatError()));
        return;
      }
      const limits = getButtonLimits(activeScrollButton);
      if (!limits) {
        dispatch(setCommandResponse(starsFormatError()));
        dispatchResetInputEvent();
        return;
      }
      const { min, max, allowFloat, setValue } = limits;
      const value = parseFloat(command[0].token);
      if (value < min || value > max || (!allowFloat && (value % 1) !== 0)) {
        dispatch(setCommandResponse(starsIllegalValueError()));
        dispatchResetInputEvent();
        return;
      }
      dispatch(setValue(value));
      dispatch(setActiveScrollButton(null));
      dispatchResetInputEvent();
      return;
    }
    // dispatch(setIsProcessingCommand(true));

    let done = false;

    if (command[0].starsFunction === MULTIFUNC) {
      done = processMultifuncCommand(dispatch, command);
    }

    if (!done) {
      const result = await starsHubConnection
        .processStarsCommand(
          command.filter((t) => !t.token?.match(/^\s*$/)),
        )
        .catch((e) => {
          if (e instanceof StarsCommandProcessingError) {
            return e.result;
          }
          return starsGenericResponse("INTERNAL SW ERROR");
        });
      dispatch(setCommandResponse(result));
      dispatchResetInputEvent();
    }

    // dispatch(setIsProcessingCommand(false));

    if (selectButtonSelected(getState(), "CURSOR_HOME")) {
      const cursorHomePosition = selectCursorHomePosition(getState()) ?? [window.innerWidth / 2, window.innerHeight / 2];
      await setCursorPosition(cursorHomePosition[0], cursorHomePosition[1]);
      setTimeout(() => {
        document.body.style.cursor = "none";
      }, 10);
    }
  };
}
