/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseStore } from "@poscon/shared-frontend";
import { Coordinate, getMagVar } from "@poscon/shared-types";
import { geoMercator } from "d3-geo";
import { Matrix, Point, Rectangle } from "pixi.js";
import * as turf from "@turf/turf";
import { RootStore } from "./redux/store";
import {
  selectMapScale,
  selectOffsetRangeCenter,
  selectRangeCenter,
  selectRangeCenterOverride,
} from "./redux/slices/starsTempSlice";
import { ListenerEntry } from "./redux/listenerEntry";
import {
  setMapScaleToRange,
  setRangeAction,
  setRangeCenterOverrideToLonLat,
  setRangeCenterOverrideToLonLatAction,
} from "./redux/thunks/mapThunks";
import { listenerMiddleware } from "./redux/listenerMiddleware";
import { selectButtonSelected } from "./redux/slices/starsSlice";

const projectionScale = 30000;

let store: RootStore;
export const injectStore = (s: RootStore) => {
  store = s;
  situationDisplayStore.rangeCenterUpdate(selectRangeCenter(store.getState()));
  situationDisplayStore.updateRange();
  situationDisplayStore.sync();
};

const listeners: ListenerEntry[] = [
  {
    predicate: (action, state, prevState) => selectRangeCenter(state) !== selectRangeCenter(prevState),
    effect: (action, { getState }) => {
      situationDisplayStore.rangeCenterUpdate(selectRangeCenter(getState()));
    }
  },
  {
    predicate: (action, state, prevState) =>
      selectRangeCenterOverride(state) !== selectRangeCenterOverride(prevState) ||
      selectOffsetRangeCenter(state) !== selectOffsetRangeCenter(prevState) ||
      selectButtonSelected(state, "OFF_CNTR") !== selectButtonSelected(prevState, "OFF_CNTR") ||
      selectMapScale(state) !== selectMapScale(prevState),
    effect: (action, { getState, dispatch }) => {
      const meta = action.meta as any;
      const prevRange = situationDisplayStore.range;
      const state = getState();
      const rangeCenterOverride = selectRangeCenterOverride(state);
      const mapScale = selectMapScale(state);
      situationDisplayStore.matrix = new Matrix(
        mapScale,
        0,
        0,
        mapScale,
        rangeCenterOverride[0],
        rangeCenterOverride[1],
      );
      situationDisplayStore.updateLonLatCenter();
      if ((action.meta as any)?.keepRange) {
        dispatch(setMapScaleToRange(prevRange));
        if (meta.keepCenter) {
          const center = selectRangeCenter(state);
          const offsetLonLat = selectOffsetRangeCenter(state);
          if (!selectButtonSelected(state, "OFF_CNTR")) {
            store?.dispatch(setRangeCenterOverrideToLonLat(offsetLonLat));
          } else {
            store?.dispatch(setRangeCenterOverrideToLonLat(center));
          }
        }
      } else {
        situationDisplayStore.updateRange();
      }
      situationDisplayStore.sync();
    },
  },
];

for (const listener of listeners) {
  // @ts-ignore
  listenerMiddleware.startListening(listener);
}

export type SituationDisplayState = {
  rect: Rectangle;
  range: number;
  matrix: Matrix;
  projection: ReturnType<typeof geoMercator>;
  nmToPx: (nm: number) => number;
  getLonLatFromSdCoord: (coord: Coordinate) => Coordinate;
  getSdCoordFromLonLat: (coord: Coordinate) => Coordinate;
};

const sdPadding = 100;

class SituationDisplayStore extends BaseStore<SituationDisplayState> {
  rect = new Rectangle(0, 0, 0, 0);

  paddedRect = new Rectangle(0, 0, 0, 0);

  projection = geoMercator().scale(projectionScale).translate([0, 0]);

  matrix = Matrix.IDENTITY;

  protected state = this.computeLatestState();

  constructor() {
    super();

    window.addEventListener("resize", () => {
      this.updateRect();
    });
    this.updateRect();
  }

  range = 100;

  lonLatCenter: Coordinate = [0, 0];

  get latestLonLatCenter() {
    return this.getLonLatFromSdCoord([
      this.rect.width / 2,
      this.rect.height / 2,
    ]);
  }

  updateLonLatCenter() {
    this.lonLatCenter = this.latestLonLatCenter;
    store.dispatch(setRangeCenterOverrideToLonLatAction(this.lonLatCenter));
  }

  updateRange() {
    this.range = this.latestRange;
    store.dispatch(setRangeAction(this.range));
  }

  rangeCenterUpdate(center: Coordinate) {
    const { innerWidth: width, innerHeight: height } = window;
    this.projection = geoMercator()
      .scale(projectionScale)
      .translate([Math.floor(width / 2), Math.floor(height / 2)])
      .center(center)
      .angle(getMagVar(...center));
    this.updateLonLatCenter();
    this.sync();
  }

  get latestRange() {
    return Math.round(
      turf.distance(
        this.getLonLatFromSdCoord([0, this.rect.height / 2]),
        this.getLonLatFromSdCoord([this.rect.width, this.rect.height / 2]),
        {
          units: "nauticalmiles",
        },
      ),
    );
  }

  getRangeAtScale(scale: number, rangeCenterOverride: Coordinate) {
    const matrix = new Matrix(
      scale,
      0,
      0,
      scale,
      rangeCenterOverride[0],
      rangeCenterOverride[1],
    );
    return Math.round(
      turf.distance(
        this.getLonLatFromSdCoord([0, this.rect.height / 2], matrix),
        this.getLonLatFromSdCoord(
          [this.rect.width, this.rect.height / 2],
          matrix,
        ),
        {
          units: "nauticalmiles",
        },
      ),
    );
  }

  private updateRect() {
    const { innerWidth: width, innerHeight: height } = window;
    this.rect = new Rectangle(0, 0, width, height);
    this.paddedRect = this.rect.clone().pad(sdPadding);
    this.projection = geoMercator()
      .scale(projectionScale)
      .translate([Math.floor(width / 2), Math.floor(height / 2)])
      .center(this.projection.center())
      .angle(getMagVar(...this.projection.center()));

    if (store) {
      const meta = {
        keepRange: true,
        resize: true,
        keepCenter: true,
      };
      const state = store.getState();
      const center = selectRangeCenter(state);
      const offsetLonLat = selectOffsetRangeCenter(state);
      if (!selectButtonSelected(state, "OFF_CNTR")) {
        store?.dispatch(setRangeCenterOverrideToLonLat(offsetLonLat, meta));
      } else {
        store?.dispatch(setRangeCenterOverrideToLonLat(center, meta));
      }
    }
  }

  // return lon/lat from sd coordinate
  getLonLatFromSdCoord(
    coordinate: Coordinate,
    matrix = this.matrix,
  ): Coordinate {
    const point = new Point(coordinate[0], coordinate[1]);
    matrix.applyInverse(point, point);
    return this.projection.invert!([point.x, point.y])!;
  }

  // return sd coordinate from lon/lat
  getSdCoordFromLonLat(
    coordinate: Coordinate,
    matrix = this.matrix,
  ): Coordinate {
    const projectedPoint = this.projection(coordinate)!;
    const point = new Point(projectedPoint[0], projectedPoint[1]);
    matrix.apply(point, point);
    return [point.x, point.y];
  }

  get pixelLen() {
    return turf.distance(
      this.getLonLatFromSdCoord([0, 0]),
      this.getLonLatFromSdCoord([1, 0]),
      {
        units: "nauticalmiles",
      },
    );
  }

  nmToPx(nm: number) {
    return Math.floor(nm / this.pixelLen);
  }

  protected computeLatestState() {
    const projection = this.projection;
    const matrix = this.matrix;
    const rect = this.rect;
    const range = this.range;
    return {
      rect,
      range,
      matrix,
      projection,
      nmToPx: this.nmToPx.bind(this),
      getLonLatFromSdCoord: this.getLonLatFromSdCoord.bind(this),
      getSdCoordFromLonLat: this.getSdCoordFromLonLat.bind(this),
    };
  }
}

export const situationDisplayStore = new SituationDisplayStore();
