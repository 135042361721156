import React, { useLayoutEffect, useRef } from "react";
import { Container } from "@pixi/react";
import { Container as PixiContainer } from "pixi.js";
import { RangeRings } from "./RangeRings";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import {
  selectButtonState,
  setButtonSelectedValue,
} from "~/redux/slices/starsSlice";
import { useGetLonLatFromSdCoord, useMapScale } from "~/contexts/sdContext";
import { setRangeCenterOverrideToLonLat } from "~/redux/thunks/mapThunks";
import {
  selectRangeCenterOverride,
  setRangeRingCenter,
} from "~/redux/slices/starsTempSlice";
import { Rectangle } from "pixi.js";
import { useWindowSize } from "usehooks-ts";
import { Geomaps } from "./Videomap";
import { layerZIndexMap } from "~/layerZIndexMap";
import { trackManager } from "~/trackManager";
import { usePixiMouseEventListener } from "@poscon/shared-frontend";
import { situationDisplayStore } from "~/situationDisplayStore";
import { dispatchMapClickEvent } from "~/customEvents";
import { Coordinate } from "@poscon/shared-types";

export const Map = () => {
  const { width, height } = useWindowSize();
  const tracksContainerRef = useRef<PixiContainer>(null);
  const mapContainerRef = useRef<PixiContainer>(null);
  const scale = useMapScale();
  const centerOverride = useRootSelector(selectRangeCenterOverride);
  const dispatch = useRootDispatch();
  const {
    PLACE_CNTR: placeCntr,
    PLACE_RR: placeRr,
  } = useRootSelector(selectButtonState);
  const getLonLatFromSdCoord = useGetLonLatFromSdCoord();

  usePixiMouseEventListener((e) => {
    const coord = getLonLatFromSdCoord([e.pageX, e.pageY]);
    if (placeCntr) {
      dispatch(setRangeCenterOverrideToLonLat(coord, { updateOffsetCenter: true }));
      dispatch(
        setButtonSelectedValue({ buttonId: "PLACE_CNTR", value: false }),
      );
      dispatch(
        setButtonSelectedValue({ buttonId: "OFF_CNTR", value: true }),
      );
      e.stopImmediatePropagation();
    } else if (placeRr) {
      dispatch(setRangeRingCenter(coord));
      dispatch(
        setButtonSelectedValue({ buttonId: "PLACE_RR", value: false }),
      );
      dispatch(
        setButtonSelectedValue({ buttonId: "RR_CNTR", value: false }),
      );
      e.stopImmediatePropagation();
    } else {
      const sdCoordinate = [e.clientX, e.clientY] satisfies Coordinate;
      const geoCoordinate = situationDisplayStore.getLonLatFromSdCoord(sdCoordinate);
      dispatchMapClickEvent({
        button: e.button,
        sdCoordinate,
        geoCoordinate,
      });
    }
  }, mapContainerRef, true);

  useLayoutEffect(() => {
    if (tracksContainerRef.current) {
      trackManager.tracksContainer = tracksContainerRef.current;
    }
    // if (nexradContainerRef.current) {
    //   nexradManager.container = nexradContainerRef.current;
    // }
  }, []);

  return (
    <>
      <Container
        ref={mapContainerRef}
        eventMode="static"
        hitArea={new Rectangle(0, 0, width, height)}
        sortableChildren
      >
        <Container
          x={centerOverride[0]}
          y={centerOverride[1]}
          scale={scale}
          zIndex={1}
          sortableChildren
        >
          <Geomaps />
          <RangeRings />
        </Container>
      </Container>
      <Container
        ref={tracksContainerRef}
        zIndex={layerZIndexMap.tracks}
        name="TRACKS"
        sortableChildren
        eventMode="static"
      />
    </>
  );
};
