import { Graphics } from "@pixi/react";
import { Graphics as PixiGraphics } from "pixi.js";
import { circle } from "@turf/turf";
import { geoPath } from "d3-geo";
import { useCallback } from "react";
import { useProjection } from "~/contexts/sdContext";
import { useRootSelector } from "~/redux/hooks";
import { selectBrightButtonValue, selectButtonSelected } from "~/redux/slices/starsSlice";
import { selectRangeCenter, selectRangeRingCenter, selectRangeRingSpacing } from "~/redux/slices/starsTempSlice";
import { computeColor } from "@poscon/shared-frontend";
import { tcwColors } from "~/theme";

export const RangeRings = () => {
  const projection = useProjection();
  const ignoreRRCenter = useRootSelector((state) => selectButtonSelected(state, "RR_CNTR"));
  const center = useRootSelector(selectRangeCenter);
  const rrCenter = useRootSelector(selectRangeRingCenter);
  const spacing = useRootSelector(selectRangeRingSpacing);
  const bright = useRootSelector((state) => selectBrightButtonValue(state, "BRITE_RR"));

  const centerToUse = ignoreRRCenter ? center : rrCenter;

  const draw = useCallback((graphics: PixiGraphics) => {
    graphics.clear();
    graphics.lineTextureStyle();
    graphics.lineStyle({
      width: 1,
      color: computeColor(tcwColors.rangeRings, bright / 100, 0),
      alignment: 1,
      native: true,
    });
    const pathGen = geoPath(projection).context(graphics as any);
    for (let i = spacing; i < 500; i += spacing) {
      const circ = circle(centerToUse, i, { units: "nauticalmiles", steps: 80 });
      pathGen(circ.geometry);
    }
  }, [projection, spacing, centerToUse, bright]);

  return bright === 0 ? null : (<Graphics eventMode="none" zIndex={0} draw={draw} />);
}
