import {
  deltaCharSizeButton,
  selectCharSizeButtonValue,
  toggleButtonSelected,
} from "./redux/slices/starsSlice";
import { Digit } from "@poscon/shared-types";
import {
  clearOpenMaps,
  selectStarsMapButtonSelected,
  selectStarsMapButtonText,
  toggleMap,
} from "./redux/slices/starsTempSlice";
import { ButtonMapValue, DcbColElement } from "./buttons";

const _dcb1KMainButtonPositions = [
  { elements: ["RANGE"] },
  { elements: ["PLACE_CNTR", "OFF_CNTR"] },
  { elements: ["RR"] },
  { elements: ["PLACE_RR", "RR_CNTR"] },
  { elements: ["MAPS_1_TO_16", "MAPS_17_TO_32"] },
  { elements: ["WX_1", "WX_2"] },
  { elements: ["WX_3", "WX_4"] },
  { elements: ["WX_5", "WX_6"] },
  { elements: ["BRITE", "CHAR_SIZE"] },
  { elements: ["LDR_DIR", "LDR_LEN"] },
  { elements: ["SSA_FILTER", "GI_TEXT"] },
  { elements: ["MODE", "SITE"] },
  { elements: ["PREF_A", "PREF_B"] },
  { elements: ["SHIFT"] },
] as const;
export type Dcb1KMainButton =
  (typeof _dcb1KMainButtonPositions)[number]["elements"][number];
export const dcb1KMainButtonPositions =
  _dcb1KMainButtonPositions as readonly DcbColElement<Dcb1KMainButton>[];
export const dcb1KMainButtons = dcb1KMainButtonPositions.flatMap(({ elements }) =>
  elements
);

export const dcb1KMainButtonMap: Partial<Record<Dcb1KMainButton, ButtonMapValue>> =
{
  MAPS_1_TO_16: {
    selectedSelector: () => false,
    textSelector: () => ["MAPS", "1-16"],
  },
  MAPS_17_TO_32: {
    selectedSelector: () => false,
    textSelector: () => ["MAPS", "17-32"],
  },
};

const _dcbShiftButtonPositions = [
  { elements: ["VOL", "BEACON_MODE"] },
  { elements: ["CURSOR_HOME", "CSR_SPD"] },
  { elements: ["MAP_UNCOR", "UNCOR"] },
  { elements: ["HISTORY", "H_RATE"] },
  { elements: ["RTQC", "MCP"] },
  { elements: ["DCB_TOP", "DCB_LEFT"] },
  { elements: ["DCB_RIGHT", "DCB_BOTTOM"] },
  { elements: ["PTL_LEN"] },
  { elements: ["PTL_OWN", "PTL_ALL"] },
  { elements: ["DWELL"] },
  { elements: ["TPA_ATPA"] },
  { elements: ["TSAS", "TIME_LINE"] },
  { elements: ["SHIFT"] },
] as const;
export type Dcb1KShiftButton =
  (typeof _dcbShiftButtonPositions)[number]["elements"][number];
export const dcb1KShiftButtonPositions =
  _dcbShiftButtonPositions as readonly DcbColElement<Dcb1KShiftButton>[];
export const dcbShiftButtons = dcb1KShiftButtonPositions.flatMap(({ elements }) =>
  elements
);

type Pref1Btn = `PREF_${Digit | `1${Exclude<Digit, "6" | "7" | "8" | "9">}`}`;
const _dcb1KPref1ButtonPositions = [
  ...Array.from(
    { length: 8 },
    (_, i) => ({
      elements: [`PREF_${i * 2}`, `PREF_${i * 2 + 1}`] as [Pref1Btn, Pref1Btn],
    }),
  ),
  { elements: ["PREF_DEFAULT", "FSSTARS"] },
  { elements: ["PREF_RESTORE", "PREF_SAVE"] },
  { elements: ["PREF_CHG_PIN", "PREF_SAVE_AS"] },
  { elements: ["PREF_DELETE", "PREF_A_DONE"] },
] as const;
export type Dcb1KPref1Button =
  (typeof _dcb1KPref1ButtonPositions)[number]["elements"][number];
export const dcb1KPref1ButtonPositions =
  _dcb1KPref1ButtonPositions as readonly DcbColElement<Dcb1KPref1Button>[];
export const dcb1KPref1Buttons = dcb1KPref1ButtonPositions.flatMap(({ elements }) =>
  elements
);

type Pref2Btn = `PREF_${`1${"6" | "7" | "8" | "9"}` | `2${Digit}` | `3${0 | 1}`}`;
const _dcb1KPref2ButtonPositions = [
  ...Array.from(
    { length: 8 },
    (_, i) => ({
      elements: [`PREF_${16 + i * 2}`, `PREF_${16 + i * 2 + 1}`] as [Pref2Btn, Pref2Btn],
    }),
  ),
  { elements: ["PREF_DEFAULT", "FSSTARS"] },
  { elements: ["PREF_RESTORE", "PREF_SAVE"] },
  { elements: ["PREF_CHG_PIN", "PREF_SAVE_AS"] },
  { elements: ["PREF_DELETE", "PREF_B_DONE"] },
] as const;
export type Dcb1KPref2Button =
  (typeof _dcb1KPref2ButtonPositions)[number]["elements"][number];
export const dcb1KPref2ButtonPositions =
  _dcb1KPref2ButtonPositions as readonly DcbColElement<Dcb1KPref2Button>[];
export const dcb1KPref2Buttons = dcb1KPref2ButtonPositions.flatMap(({ elements }) =>
  elements
);

export const dcb1KPrefButtonMap: Partial<Record<Dcb1KPref1Button | Dcb1KPref2Button, ButtonMapValue>> =
{
  PREF_A_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("PREF_A"),
  },
  PREF_B_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("PREF_B"),
  },
};

type Map1Btn = `MAP_${Digit | `1${0 | 1 | 2 | 3 | 4 | 5}`}`;
const _dcbMap1ButtonPositions = [
  { elements: ["MAPS1_DONE", "CLR_ALL"] },
  ...Array.from(
    { length: 8 },
    (_, i) => ({
      elements: [`MAP_${i * 2}`, `MAP_${i * 2 + 1}`] as [
        Map1Btn,
        Map1Btn,
      ],
    }),
  ),
  { elements: ["GEO_MAPS", "MAPS_AIRPORT"] },
  { elements: ["SYS_PROC", "MAPS_CURRENT"] },
] as const;
export type Dcb1KMap1Button =
  (typeof _dcbMap1ButtonPositions)[number]["elements"][number];
export const dcb1KMap1ButtonPositions =
  _dcbMap1ButtonPositions as readonly DcbColElement<Dcb1KMap1Button>[];
export const dcb1KMap1Buttons = dcb1KMap1ButtonPositions.flatMap(({ elements }) =>
  elements
);

type Map2Btn = `MAP_${17 | 18 | 19 | `2${Digit}` | `3${0 | 1 | 2}`}`;
const _dcbMap2ButtonPositions = [
  { elements: ["MAPS2_DONE", "CLR_ALL"] },
  ...Array.from(
    { length: 8 },
    (_, i) => ({
      elements: [`MAP_${16 + i * 2}`, `MAP_${16 + i * 2 + 1}`] as [
        Map2Btn,
        Map2Btn,
      ],
    }),
  ),
  { elements: ["GEO_MAPS", "MAPS_AIRPORT"] },
  { elements: ["SYS_PROC", "MAPS_CURRENT"] },
] as const;
export type Dcb1KMap2Button =
  (typeof _dcbMap2ButtonPositions)[number]["elements"][number];
export const dcb1KMap2ButtonPositions =
  _dcbMap2ButtonPositions as readonly DcbColElement<Dcb1KMap2Button>[];
export const dcb1KMap2Buttons = dcb1KMap2ButtonPositions.flatMap(({ elements }) =>
  elements
);

// TODO: implement
export const dcb1KMapButtonMap: Partial<Record<Dcb1KMap1Button | Dcb1KMap2Button, ButtonMapValue>> = {
  MAPS1_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("MAPS_1_TO_16"),
  },
  MAPS2_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("MAPS_17_TO_32"),
  },
  CLR_ALL: {
    textSelector: () => ["CLR ALL"],
    onclick: () => clearOpenMaps(),
    selectedSelector: () => false,
  },
  MAPS_AIRPORT: {
    textSelector: () => ["AIRPORT"],
  },
  MAPS_CURRENT: {
    textSelector: () => ["CURRENT"],
  },
  ...Object.fromEntries(Array.from({ length: 38 }, (_, i) => {
    return [`MAP_${i}` as Map1Btn, {
      textSelector: (state) => selectStarsMapButtonText(state, i),
      onclick: () => toggleMap(i),
      selectedSelector: (state) => selectStarsMapButtonSelected(state, i),
    }];
  })),
};

const _dcbCharSizeButtonPositions = [
  { elements: ["CHAR_SIZE_DATA_BLOCKS"] },
  { elements: ["CHAR_SIZE_LISTS"] },
  { elements: ["CHAR_SIZE_DCB"] },
  { elements: ["CHAR_SIZE_TOOLS"] },
  { elements: ["CHAR_SIZE_POS"] },
  { elements: ["CHAR_SIZE_DONE"] },
] as const;
export type DcbCharSizeButton =
  (typeof _dcbCharSizeButtonPositions)[number]["elements"][number];
export type StarsCharSizeButton = Exclude<DcbCharSizeButton, "CHAR_SIZE_DONE">;
export const dcbCharSizeButtonPositions =
  _dcbCharSizeButtonPositions as readonly DcbColElement<DcbCharSizeButton>[];
export const dcbCharSizeButtons = dcbCharSizeButtonPositions.flatMap((
  { elements },
) => elements);
export const starsCharSizeButtons = dcbCharSizeButtons.filter((
  b,
): b is StarsCharSizeButton => b !== "CHAR_SIZE_DONE");
export const dcbCharSizeButtonMap: Partial<
  Record<DcbCharSizeButton, ButtonMapValue>
> = {
  CHAR_SIZE_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("CHAR_SIZE"),
  },
  ...Object.fromEntries(starsCharSizeButtons.map((b) => [b, {
    textSelector: (
      state,
    ) => [
        ...b.replace("CHAR_SIZE_", "").split("_"),
        selectCharSizeButtonValue(state, b).toString(),
      ],
    onwheel: (e) =>
      deltaCharSizeButton({ buttonId: b, delta: e.deltaY < 0 ? 1 : -1 }),
  }])),
};

const _dcbSiteButtonPositions = [
  { elements: ["SITE_1"] },
  { elements: ["SITE_2"] },
  { elements: ["SITE_3"] },
  { elements: ["SITE_4"] },
  { elements: ["SITE_MULTI"] },
  { elements: ["SITE_FUSED"] },
  { elements: ["SITE_DONE"] },
] as const;
export type DcbSiteButton =
  (typeof _dcbSiteButtonPositions)[number]["elements"][number];
export const dcbSiteButtonPositions =
  _dcbSiteButtonPositions as readonly DcbColElement<DcbSiteButton>[];
export const dcbSiteButtons = dcbSiteButtonPositions.flatMap(({ elements }) =>
  elements
);
export const dcbSiteButtonMap: Partial<Record<DcbSiteButton, ButtonMapValue>> =
{
  SITE_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("SITE"),
  },
  SITE_MULTI: {
    textSelector: () => ["MULTI"],
  },
  SITE_FUSED: {
    textSelector: () => ["FUSED"],
  },
};

const _dcbModeButtonPositions = [
  { elements: ["EFSL"] },
  { elements: ["FSL"] },
  { elements: ["TRNG"] },
  { elements: ["FMA"] },
  { elements: ["DSF"] },
  { elements: ["MODE_DONE"] },
] as const;
export type DcbModeButton =
  (typeof _dcbModeButtonPositions)[number]["elements"][number];
export const dcbModeButtonPositions =
  _dcbModeButtonPositions as readonly DcbColElement<DcbModeButton>[];
export const dcbModeButtons = dcbModeButtonPositions.flatMap(({ elements }) =>
  elements
);
export const dcbModeButtonMap: Partial<Record<DcbModeButton, ButtonMapValue>> =
{
  MODE_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("MODE"),
  },
};

export const dcb1KButtonPropsMap = {
  ...dcb1KMainButtonMap,
  ...dcb1KMapButtonMap,
  ...dcbCharSizeButtonMap,
  ...dcbSiteButtonMap,
  ...dcbModeButtonMap,
  ...dcb1KPrefButtonMap,
};

export const buttonList1K = [
  ...dcb1KMainButtons,
  ...dcbShiftButtons,
  ...dcb1KMap1Buttons,
  ...dcbCharSizeButtons,
  ...dcbSiteButtons,
  ...dcbModeButtons,
  ...dcb1KPref1Buttons,
  ...dcb1KPref2Buttons,
];
