import { Action, Selector } from "@reduxjs/toolkit";
import { starsFontDimMap } from "./constants";
import { RootState, RootThunkAction } from "./redux/store";
import { FederatedPointerEvent, FederatedWheelEvent } from "pixi.js";
import {
  changeLeaderDir,
  deltaBrightButton,
  deltaCharSizeButton,
  deltaCursorSpeedThunk,
  deltaHistoryLen,
  deltaLdrLen,
  deltaPtlLength,
  deltaVolume,
  selectBrightButtonValue,
  selectButtonSelected,
  selectCharSizeButtonValue,
  selectCursorSpeed,
  selectDcbDir,
  selectDwell,
  selectHistoryLen,
  selectLdrDir,
  selectLdrLength,
  selectPtlLength,
  selectVolume,
  setDcbDir,
  toggleButtonSelected,
} from "./redux/slices/starsSlice";
import { Digit } from "@poscon/shared-types";
import {
  deltaRange,
  setRangeCenterOverrideToLonLat,
} from "./redux/thunks/mapThunks";
import {
  clearOpenMaps,
  deltaRangeRingSpacing,
  selectIsActiveScrollButton,
  selectOffsetRangeCenter,
  selectRangeCenter,
  selectRangeRingSpacing,
  selectStarsMapButtonSelected,
  selectStarsMapButtonText,
  toggleMap,
} from "./redux/slices/starsTempSlice";
import { ButtonId } from "./buttonId";

const { width: fontWidth, height: fontHeight } = starsFontDimMap[2];
export const dcbHeight = fontHeight * 5;
export const smallDcbButtonHeight = dcbHeight / 2;
export const dcbButtonWidth = fontWidth * 7;

export type ButtonMapValue = {
  textSelector?: (state: RootState) => string[];
  selectedSelector?: Selector<RootState, boolean>;
  onclick?: () => Action<any> | RootThunkAction;
  onmousedown?: (event: FederatedPointerEvent) => Action<any> | RootThunkAction;
  onwheel?: (
    event: Pick<FederatedWheelEvent, "shiftKey" | "deltaY">,
  ) => Action<any> | RootThunkAction;
  disabled?: boolean;
};

export type DcbColElement<T extends string> = {
  width?: number;
  height?: number;
  elements: readonly [T] | readonly [T, T];
};
const _dcbMainButtonPositions = [
  { elements: ["RANGE"] },
  { elements: ["PLACE_CNTR", "OFF_CNTR"] },
  { elements: ["RR"] },
  { elements: ["PLACE_RR", "RR_CNTR"] },
  { elements: ["MAPS"] },
  { elements: ["MAP_0", "MAP_1"] },
  { elements: ["MAP_2", "MAP_3"] },
  { elements: ["MAP_4", "MAP_5"] },
  { elements: ["WX_1"], width: fontWidth * 4 },
  { elements: ["WX_2"], width: fontWidth * 4 },
  { elements: ["WX_3"], width: fontWidth * 4 },
  { elements: ["WX_4"], width: fontWidth * 4 },
  { elements: ["WX_5"], width: fontWidth * 4 },
  { elements: ["WX_6"], width: fontWidth * 4 },
  { elements: ["BRITE"] },
  { elements: ["LDR_DIR", "LDR_LEN"] },
  { elements: ["CHAR_SIZE"] },
  { elements: ["MODE"] },
  { elements: ["SITE"] },
  { elements: ["PREF"] },
  { elements: ["SSA_FILTER", "GI_TEXT"] },
  { elements: ["SHIFT"] },
] as const;
export type DcbMainButton =
  (typeof _dcbMainButtonPositions)[number]["elements"][number];
export const dcbMainButtonPositions =
  _dcbMainButtonPositions as readonly DcbColElement<DcbMainButton>[];
export const dcbMainButtons = dcbMainButtonPositions.flatMap(({ elements }) =>
  elements
);

export const dcbMainButtonMap: Partial<Record<DcbMainButton, ButtonMapValue>> =
{
  RANGE: {
    onwheel: (e) =>
      deltaRange((e.shiftKey ? 8 : 1) * (e.deltaY < 0 ? 1 : -1)),
  },
  OFF_CNTR: {
    onclick: () => (dispatch, getState) => {
      const state = getState();
      const center = selectRangeCenter(state);
      const offsetLonLat = selectOffsetRangeCenter(state);
      if (!selectButtonSelected(state, "OFF_CNTR")) {
        dispatch(setRangeCenterOverrideToLonLat(offsetLonLat));
      } else {
        dispatch(setRangeCenterOverrideToLonLat(center));
      }
      dispatch(toggleButtonSelected("OFF_CNTR"));
    },
  },
  RR: {
    textSelector: (state) => ["RR", selectRangeRingSpacing(state).toString()],
    onwheel: (e) => deltaRangeRingSpacing(e.deltaY < 0 ? 1 : -1),
  },
  MAPS: {
    selectedSelector: () => false,
  },
  MAP_0: {
    textSelector: (state) => selectStarsMapButtonText(state, 0),
    selectedSelector: (state) => selectStarsMapButtonSelected(state, 0),
    onclick: () => toggleMap(0),
  },
  MAP_1: {
    textSelector: (state) => selectStarsMapButtonText(state, 1),
    selectedSelector: (state) => selectStarsMapButtonSelected(state, 1),
    onclick: () => toggleMap(1),
  },
  MAP_2: {
    textSelector: (state) => selectStarsMapButtonText(state, 2),
    selectedSelector: (state) => selectStarsMapButtonSelected(state, 2),
    onclick: () => toggleMap(2),
  },
  MAP_3: {
    textSelector: (state) => selectStarsMapButtonText(state, 3),
    selectedSelector: (state) => selectStarsMapButtonSelected(state, 3),
    onclick: () => toggleMap(3),
  },
  MAP_4: {
    textSelector: (state) => selectStarsMapButtonText(state, 4),
    selectedSelector: (state) => selectStarsMapButtonSelected(state, 4),
    onclick: () => toggleMap(4),
  },
  MAP_5: {
    textSelector: (state) => selectStarsMapButtonText(state, 5),
    selectedSelector: (state) => selectStarsMapButtonSelected(state, 5),
    onclick: () => toggleMap(5),
  },
  WX_1: {
    textSelector: () => ["WX1", "AVL"],
  },
  WX_2: {
    textSelector: () => ["WX2", "AVL"],
  },
  WX_3: {
    textSelector: () => ["WX3", "AVL"],
  },
  WX_4: {
    textSelector: () => ["WX4", "AVL"],
  },
  WX_5: {
    textSelector: () => ["WX5", "AVL"],
  },
  WX_6: {
    textSelector: () => ["WX6", "AVL"],
  },
  BRITE: {
    selectedSelector: () => false,
  },
  LDR_DIR: {
    textSelector: (state) => ["LDR DIR", selectLdrDir(state)],
    onwheel: (e) => changeLeaderDir(e.deltaY < 0 ? 1 : -1),
  },
  LDR_LEN: {
    textSelector: (state) => ["LDR", selectLdrLength(state).toString()],
    onwheel: (e) => deltaLdrLen(e.deltaY < 0 ? 1 : -1),
  },
  CHAR_SIZE: {
    selectedSelector: () => false,
  },
  MODE: {
    textSelector: () => ["MODE", "FSL"],
    selectedSelector: () => false,
  },
  SITE: {
    textSelector: () => ["SITE", "FUSED"],
    selectedSelector: () => false,
  },
  SSA_FILTER: {
    selectedSelector: () => false,
  },
  GI_TEXT: {
    textSelector: () => ["GI TEXT", "FILTER"],
    selectedSelector: () => false,
  },
};

const _dcbShiftButtonPositions = [
  { elements: ["VOL"] },
  { elements: ["HISTORY", "H_RATE"] },
  { elements: ["CURSOR_HOME"] },
  { elements: ["CSR_SPD"] },
  { elements: ["MAP_UNCOR"] },
  { elements: ["UNCOR"] },
  { elements: ["BEACON_MODE"] },
  { elements: ["RTQC"] },
  { elements: ["MCP"] },
  { elements: ["DCB_TOP", "DCB_LEFT"] },
  { elements: ["DCB_RIGHT", "DCB_BOTTOM"] },
  { elements: ["PTL_LEN"] },
  { elements: ["PTL_OWN", "PTL_ALL"] },
  { elements: ["DWELL"] },
  { elements: ["TPA_ATPA"] },
  { elements: ["TSAS", "TIME_LINE"] },
  { elements: ["SHIFT"] },
] as const;
export type DcbShiftButton =
  (typeof _dcbShiftButtonPositions)[number]["elements"][number];
export const dcbShiftButtonPositions =
  _dcbShiftButtonPositions as readonly DcbColElement<DcbShiftButton>[];
export const dcbShiftButtons = dcbShiftButtonPositions.flatMap(({ elements }) =>
  elements
);

export const dcbShiftButtonMap: Partial<
  Record<DcbShiftButton, ButtonMapValue>
> = {
  VOL: {
    textSelector: (state) => ["VOL", selectVolume(state).toString()],
    onwheel: (e) => deltaVolume(e.deltaY > 0 ? -1 : 1),
  },
  HISTORY: {
    textSelector: (state) => ["HISTORY", selectHistoryLen(state).toString()],
    onwheel: (e) => deltaHistoryLen(e.deltaY > 0 ? -1 : 1),
  },
  CSR_SPD: {
    textSelector: (state) => ["CSR SPD", selectCursorSpeed(state).toString()],
    onwheel: (e) => deltaCursorSpeedThunk(e.deltaY > 0 ? -1 : 1),
  },
  BEACON_MODE: {
    textSelector: () => ["BEACON", "MODE-2"],
  },
  DCB_TOP: {
    selectedSelector: (state) => selectDcbDir(state) === "TOP",
    onmousedown: () => setDcbDir("TOP"),
  },
  DCB_RIGHT: {
    selectedSelector: (state) => selectDcbDir(state) === "RIGHT",
    onmousedown: () => setDcbDir("RIGHT"),
  },
  DCB_LEFT: {
    selectedSelector: (state) => selectDcbDir(state) === "LEFT",
    onmousedown: () => setDcbDir("LEFT"),
  },
  DCB_BOTTOM: {
    selectedSelector: (state) => selectDcbDir(state) === "BOTTOM",
    onmousedown: () => setDcbDir("BOTTOM"),
  },
  PTL_LEN: {
    textSelector: (state) => ["PTL", "LNTH", selectPtlLength(state).toString()],
    onwheel: (e) => deltaPtlLength(e.deltaY > 0 ? -1 : 1),
  },
  PTL_OWN: {
    textSelector: () => ["PTL OWN"],
  },
  PTL_ALL: {
    textSelector: () => ["PTL ALL"],
  },
  DWELL: {
    textSelector: (state) => ["DWELL", selectDwell(state) ? "ON" : "OFF"],
  },
  TPA_ATPA: {
    textSelector: () => ["TPA/", "ATPA"],
  },
  SHIFT: {
    selectedSelector: () => false,
  },
};

export type DcbGiTextFilterButton = `GI_${Digit}` | "GI_TEXT_FILTER_DONE";
export const dcbGiTextFilterButtons = Array.from(
  { length: 10 },
  (_, i) => `GI_${i}`,
) as DcbGiTextFilterButton[];
export const dcbGiTextFilterButtonPositions = [
  ...Array.from(
    { length: 5 },
    (_, i) => ({ elements: [`GI_${i * 2}`, `GI_${i * 2 + 1}`] as const }),
  ),
  { elements: ["GI_TEXT_FILTER_DONE"] },
] as DcbColElement<DcbGiTextFilterButton>[];
export const dcbGiTextFilterButtonMap: Partial<
  Record<DcbGiTextFilterButton, ButtonMapValue>
> = {
  GI_0: {
    textSelector: () => ["MAIN"],
  },
  GI_TEXT_FILTER_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("GI_TEXT"),
  },
};

const _dcbSsaFilterButtonPositions = [
  { elements: ["ALL", "WX"] },
  { elements: ["TIME", "ALTSTG"] },
  { elements: ["STATUS", "PLAN"] },
  { elements: ["RADAR", "CODES"] },
  { elements: ["SPC", "SYS"] },
  { elements: ["RANGE", "PTL"] },
  { elements: ["ALT_FIL", "NAS_IF"] },
  { elements: ["INTRAIL", "INTRAIL_DIST"] },
  { elements: ["SSA_FILTER_AIRPORT", "OP_MODE"] },
  { elements: ["TT", "WX_HIST"] },
  { elements: ["QL", "TW"] },
  { elements: ["CON_CPL", "OFF_IND"] },
  { elements: ["CRDA", "FLOW"] },
  { elements: ["AMZ", "TBFM"] },
  { elements: ["SSA_FILTER_DONE"] },
] as const;
export type DcbSsaFilterButton =
  (typeof _dcbSsaFilterButtonPositions)[number]["elements"][number];
export const dcbSsaFilterButtonPositions =
  _dcbSsaFilterButtonPositions as readonly DcbColElement<DcbSsaFilterButton>[];
export const dcbSsaFilterButtons = dcbSsaFilterButtonPositions.flatMap((
  { elements },
) => elements);

export const dcbSsaFilterButtonMap: Partial<
  Record<DcbSsaFilterButton, ButtonMapValue>
> = {
  ALT_FIL: {
    textSelector: () => ["ALT FIL"],
  },
  NAS_IF: {
    textSelector: () => ["NAS I/F"],
  },
  INTRAIL_DIST: {
    textSelector: () => ["2.5"],
  },
  CON_CPL: {
    textSelector: () => ["CON/CPL"],
  },
  OFF_IND: {
    textSelector: () => ["OFF IND"],
  },
  SSA_FILTER_AIRPORT: {
    textSelector: () => ["AIRPORT"],
  },
  SSA_FILTER_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("SSA_FILTER"),
  },
};

const _dcbBriteButtonPositions = [
  { elements: ["BRITE_DCB", "BKC"] },
  { elements: ["MPA", "MPB"] },
  { elements: ["FDB", "LST"] },
  { elements: ["POS", "LDB"] },
  { elements: ["OTH", "TLS"] },
  { elements: ["BRITE_RR", "CMP"] },
  { elements: ["BCN", "PRI"] },
  { elements: ["HST", "WX"] },
  { elements: ["WXC", "BRITE_DONE"] },
] as const;
export type DcbBriteButton =
  (typeof _dcbBriteButtonPositions)[number]["elements"][number];
export type StarsBrightButton = Exclude<DcbBriteButton, "BRITE_DONE">;
export const dcbBriteButtonPositions =
  _dcbBriteButtonPositions as readonly DcbColElement<DcbBriteButton>[];
export const dcbBriteButtons = dcbBriteButtonPositions.flatMap(({ elements }) =>
  elements
);
export const starsBrightButtons = dcbBriteButtons.filter((
  b,
): b is StarsBrightButton => b !== "BRITE_DONE");
export const dcbBriteButtonMap: Partial<
  Record<DcbBriteButton, ButtonMapValue>
> = {
  BRITE_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("BRITE"),
  },
  ...Object.fromEntries(starsBrightButtons.map((b) => [b, {
    textSelector: (state) => {
      const v = selectBrightButtonValue(state, b);
      return [b.replace("BRITE_", ""), v === 0 ? "OFF" : v.toString()];
    },
    onwheel: (e) =>
      deltaBrightButton({ buttonId: b, delta: e.deltaY < 0 ? 1 : -1 }),
  }])),
};

type PrefBtn = `PREF_${Digit | `${1 | 2}${Digit}` | `3${0 | 1}`}`;
const _dcbPrefButtonPositions = [
  ...Array.from(
    { length: 16 },
    (_, i) => ({
      elements: [`PREF_${i * 2}`, `PREF_${i * 2 + 1}`] as [PrefBtn, PrefBtn],
    }),
  ),
  { elements: ["PREF_DEFAULT", "FSSTARS"] },
  { elements: ["PREF_RESTORE", "PREF_SAVE"] },
  { elements: ["PREF_CHG_PIN", "PREF_SAVE_AS"] },
  { elements: ["PREF_DELETE", "PREF_DONE"] },
] as const;
export type DcbPrefButton =
  (typeof _dcbPrefButtonPositions)[number]["elements"][number];
export const dcbPrefButtonPositions =
  _dcbPrefButtonPositions as readonly DcbColElement<DcbPrefButton>[];
export const dcbPrefButtons = dcbPrefButtonPositions.flatMap(({ elements }) =>
  elements
);
export const dcbPrefButtonMap: Partial<Record<DcbPrefButton, ButtonMapValue>> =
{
  ...Object.fromEntries(
    Array.from(Array.from({ length: 32 }, (_, i) => [`PREF_${i}`, {
      textSelector: () => [`${i + 1}`, ""],
    }])),
  ),
  PREF_DEFAULT: {
    textSelector: () => ["DEFAULT"],
  },
  PREF_RESTORE: {
    textSelector: () => ["RESTORE"],
  },
  PREF_SAVE: {
    textSelector: () => ["SAVE"],
  },
  PREF_CHG_PIN: {
    textSelector: () => ["CHG PIN"],
  },
  PREF_SAVE_AS: {
    textSelector: () => ["SAVE AS"],
  },
  PREF_DELETE: {
    textSelector: () => ["DELETE"],
  },
  PREF_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("PREF"),
  },
};

type MapBtn = `MAP_${| 6
  | 7
  | 8
  | 9
  | `${1 | 2}${Digit}`
  | `3${Exclude<Digit, 8 | 9>}`}`;
const _dcbMapButtonPositions = [
  { elements: ["MAPS_DONE", "CLR_ALL"] },
  ...Array.from(
    { length: 16 },
    (_, i) => ({
      elements: [`MAP_${6 + i * 2}`, `MAP_${6 + i * 2 + 1}`] as [
        MapBtn,
        MapBtn,
      ],
    }),
  ),
  { elements: ["GEO_MAPS", "MAPS_AIRPORT"] },
  { elements: ["SYS_PROC", "MAPS_CURRENT"] },
] as const;
export type DcbMapButton =
  (typeof _dcbMapButtonPositions)[number]["elements"][number];
export const dcbMapButtonPositions =
  _dcbMapButtonPositions as readonly DcbColElement<DcbMapButton>[];
export const dcbMapButtons = dcbMapButtonPositions.flatMap(({ elements }) =>
  elements
);
// TODO: implement
export const dcbMapButtonMap: Partial<Record<DcbMapButton, ButtonMapValue>> = {
  MAPS_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("MAPS"),
  },
  CLR_ALL: {
    textSelector: () => ["CLR ALL"],
    onclick: () => clearOpenMaps(),
    selectedSelector: () => false,
  },
  MAPS_AIRPORT: {
    textSelector: () => ["AIRPORT"],
  },
  MAPS_CURRENT: {
    textSelector: () => ["CURRENT"],
  },
  ...Object.fromEntries(Array.from({ length: 32 }, (_, i) => {
    return [`MAP_${i + 6}` as MapBtn, {
      textSelector: (state) => selectStarsMapButtonText(state, i + 6),
      onclick: () => toggleMap(i + 6),
      selectedSelector: (state) => selectStarsMapButtonSelected(state, i + 6),
    }];
  })),
};

const _dcbCharSizeButtonPositions = [
  { elements: ["CHAR_SIZE_DATA_BLOCKS"] },
  { elements: ["CHAR_SIZE_LISTS"] },
  { elements: ["CHAR_SIZE_DCB"] },
  { elements: ["CHAR_SIZE_TOOLS"] },
  { elements: ["CHAR_SIZE_POS"] },
  { elements: ["CHAR_SIZE_DONE"] },
] as const;
export type DcbCharSizeButton =
  (typeof _dcbCharSizeButtonPositions)[number]["elements"][number];
export type StarsCharSizeButton = Exclude<DcbCharSizeButton, "CHAR_SIZE_DONE">;
export const dcbCharSizeButtonPositions =
  _dcbCharSizeButtonPositions as readonly DcbColElement<DcbCharSizeButton>[];
export const dcbCharSizeButtons = dcbCharSizeButtonPositions.flatMap((
  { elements },
) => elements);
export const starsCharSizeButtons = dcbCharSizeButtons.filter((
  b,
): b is StarsCharSizeButton => b !== "CHAR_SIZE_DONE");
export const dcbCharSizeButtonMap: Partial<
  Record<DcbCharSizeButton, ButtonMapValue>
> = {
  CHAR_SIZE_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("CHAR_SIZE"),
  },
  ...Object.fromEntries(starsCharSizeButtons.map((b) => [b, {
    textSelector: (
      state,
    ) => [
        ...b.replace("CHAR_SIZE_", "").split("_"),
        selectCharSizeButtonValue(state, b).toString(),
      ],
    onwheel: (e) =>
      deltaCharSizeButton({ buttonId: b, delta: e.deltaY < 0 ? 1 : -1 }),
  }])),
};

const _dcbSiteButtonPositions = [
  { elements: ["SITE_1"] },
  { elements: ["SITE_2"] },
  { elements: ["SITE_3"] },
  { elements: ["SITE_4"] },
  { elements: ["SITE_MULTI"] },
  { elements: ["SITE_FUSED"] },
  { elements: ["SITE_DONE"] },
] as const;
export type DcbSiteButton =
  (typeof _dcbSiteButtonPositions)[number]["elements"][number];
export const dcbSiteButtonPositions =
  _dcbSiteButtonPositions as readonly DcbColElement<DcbSiteButton>[];
export const dcbSiteButtons = dcbSiteButtonPositions.flatMap(({ elements }) =>
  elements
);
export const dcbSiteButtonMap: Partial<Record<DcbSiteButton, ButtonMapValue>> =
{
  SITE_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("SITE"),
  },
  SITE_MULTI: {
    textSelector: () => ["MULTI"],
  },
  SITE_FUSED: {
    textSelector: () => ["FUSED"],
  },
};

const _dcbModeButtonPositions = [
  { elements: ["EFSL"] },
  { elements: ["FSL"] },
  { elements: ["TRNG"] },
  { elements: ["FMA"] },
  { elements: ["DSF"] },
  { elements: ["MODE_DONE"] },
] as const;
export type DcbModeButton =
  (typeof _dcbModeButtonPositions)[number]["elements"][number];
export const dcbModeButtonPositions =
  _dcbModeButtonPositions as readonly DcbColElement<DcbModeButton>[];
export const dcbModeButtons = dcbModeButtonPositions.flatMap(({ elements }) =>
  elements
);
export const dcbModeButtonMap: Partial<Record<DcbModeButton, ButtonMapValue>> =
{
  MODE_DONE: {
    textSelector: () => ["DONE"],
    onclick: () => toggleButtonSelected("MODE"),
  },
};

export const dcb2KButtonPropsMap = {
  ...dcbMainButtonMap,
  ...dcbShiftButtonMap,
  ...dcbGiTextFilterButtonMap,
  ...dcbSsaFilterButtonMap,
  ...dcbBriteButtonMap,
  ...dcbMapButtonMap,
  ...dcbCharSizeButtonMap,
  ...dcbSiteButtonMap,
  ...dcbModeButtonMap,
  ...dcbPrefButtonMap,
};

export const buttonList2K = [
  ...dcbMainButtons,
  ...dcbShiftButtons,
  ...dcbGiTextFilterButtons,
  ...dcbSsaFilterButtons,
  ...dcbBriteButtons,
  ...dcbMapButtons,
  ...dcbCharSizeButtons,
  ...dcbSiteButtons,
  ...dcbModeButtons,
  ...dcbPrefButtons,
];
