import React, { useSyncExternalStore } from "react";
import { useRootSelector } from "~/redux/hooks";
import { selectMapScale } from "~/redux/slices/starsTempSlice";
import { situationDisplayStore } from "~/situationDisplayStore";

type SituationDisplayContextValue = ReturnType<typeof situationDisplayStore.getState>;
export const SituationDisplayContext = React.createContext<SituationDisplayContextValue | null>(null);

type SituationDisplayContextProviderProps = {
  children: React.ReactNode;
};
export const SituationDisplayContextProvider = ({ children }: SituationDisplayContextProviderProps) => {
  const situationDisplayStoreValue = useSyncExternalStore(
    situationDisplayStore.subscribe,
    situationDisplayStore.getState,
  );

  return <SituationDisplayContext.Provider value={situationDisplayStoreValue}>{children}</SituationDisplayContext.Provider>;
};

export const useSituationDisplay = () => {
  const contextValue = React.useContext(SituationDisplayContext);
  if (contextValue === null) {
    throw new Error("useSituationDisplayContext must be used within a SituationDisplayContextProvider");
  }
  return contextValue;
};

export const useProjection = () => {
  return useSituationDisplay().projection;
};

export const useMapScale = () => {
  return useRootSelector(selectMapScale);
};

export const useGetLonLatFromSdCoord = () => {
  return useSituationDisplay().getLonLatFromSdCoord;
}

export const useGetSdCoordFromLonLat = () => {
  return useSituationDisplay().getSdCoordFromLonLat;
};

export const useNmToPx = () => {
  return useSituationDisplay().nmToPx;
}

