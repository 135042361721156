import {
  Coordinate,
  mod,
  RequireKeys,
  StarsTrack,
  TrackId,
} from "@poscon/shared-types";
import { along, distance, lineString } from "@turf/turf";

const incomingInterval = 2e3;
export const targetUpdatePublishInterval = incomingInterval / 2;

export const numTrackUpdateInterpolations = 1; // Math.ceil((incomingInterval / targetUpdatePublishInterval) - 1);
const stepDivider = numTrackUpdateInterpolations + 1;

type Report = Record<TrackId, RequireKeys<StarsTrack, "target">>;
type InterpolatedReport = Pick<
  StarsTrack,
  "interpolatedTrack" | "interpolatedHistories" | "id"
>[];

export const interpolatedTrackQueueRef: { reports: InterpolatedReport[] } = {
  reports: [],
};

const lastReport: { timeStamp: number; report: Report } = {
  timeStamp: 0,
  report: {},
};

function getInterpolatedValue(
  start: number,
  end: number,
  step: number,
  divider: number,
) {
  return Math.round((start * (divider - step) + end * step) / divider);
}

export function interpolateTrackUpdates(report: Report) {
  const interpolatedReports: InterpolatedReport[] = Array
    .from({ length: numTrackUpdateInterpolations }, () => []);

  for (const [trackId, track] of Object.entries(report)) {
    const target = track.target;
    const prevTrack = lastReport.report[trackId];
    if (prevTrack) {
      const modeCCorrected = prevTrack.modeCCorrected && track.modeCCorrected;
      const prevTarget = prevTrack.target;
      const start = prevTrack.position;
      const end = track.position;
      const lineStr = lineString([start, end]);
      const dist = distance(start, end);
      const stepLen = dist / stepDivider;
      for (let k = 0; k < numTrackUpdateInterpolations; k++) {
        const step = k + 1;
        const position = along(lineStr, stepLen * step).geometry
          .coordinates as Coordinate;
        const modeCAltitude = getInterpolatedValue(
          prevTrack.modeCAltitude ?? prevTarget.altitudeBaro,
          track.modeCAltitude ?? target.altitudeBaro,
          step,
          stepDivider,
        );
        const groundSpeed = getInterpolatedValue(
          prevTarget.groundSpeed,
          target.groundSpeed,
          step,
          stepDivider,
        );
        const tracking = mod(
          getInterpolatedValue(
            prevTarget.tracking,
            target.tracking,
            step,
            stepDivider,
          ),
          360,
        );
        const interpolatedTrack = {
          position,
          groundSpeed,
          modeCAltitude,
          modeCCorrected,
          tracking,
          heading: tracking,
        };
        interpolatedReports[k]!.push({
          id: trackId,
          interpolatedHistories: k === numTrackUpdateInterpolations - 1 ? track.histories : prevTrack.histories,
          interpolatedTrack,
        });
      }
    }
  }
  const convertedReport = Object.values(report).map(
    (
      {
        id,
        position,
        target: { groundSpeed, tracking, heading, altitudeBaro },
        histories,
        modeCAltitude,
        modeCCorrected,
      },
    ) => {
      const interpolatedTrack = {
        position,
        groundSpeed,
        modeCAltitude: modeCAltitude ?? altitudeBaro,
        modeCCorrected,
        tracking,
        heading,
      };
      return {
        id,
        interpolatedHistories: histories,
        interpolatedTrack,
      };
    },
  );
  interpolatedTrackQueueRef.reports.push(
    ...interpolatedReports,
    convertedReport,
  );
  lastReport.timeStamp = Date.now();
  lastReport.report = report;
}
