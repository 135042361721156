import { connectionReducer, connectionSlice, navdataApi } from "@poscon/shared-frontend";
import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { injectStore } from "./injectStore";
import { starsReducer } from "./slices/starsSlice";
import { starsTempReducer } from "./slices/starsTempSlice";
import { listenerMiddleware } from "./listenerMiddleware";
import { aircraftReducer } from "./slices/starsAircraftSlice";

export const store = configureStore({
  reducer: {
    aircraft: aircraftReducer,
    stars: starsReducer,
    starsTemp: starsTempReducer,
    [connectionSlice.name]: connectionReducer,
    [navdataApi.reducerPath]: navdataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat([
      navdataApi.middleware,
      listenerMiddleware.middleware,
    ]),
});

injectStore(store);

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type RootThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type RootStore = typeof store;
