import {
  computeColor,
  setCursorPosition,
  useCustomEventListener,
  useFocused,
} from "@poscon/shared-frontend";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { type Graphics as PixiGraphics, Rectangle } from "pixi.js";
import { ComponentType, useLayoutEffect, useRef, useState } from "react";
import {
  ButtonMapValue,
  dcbButtonWidth,
  dcbHeight,
} from "~/buttons";
import { actualSelectButtonSelected, ButtonId, dcbButtonPropsMap } from "~/buttonId";
import { starsFontNameMap, starsFontDimMap } from "~/constants";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import {
  selectBrightButtonValue,
  selectCharSizeButtonValue,
  toggleButtonSelected,
} from "~/redux/slices/starsSlice";
import { useSituationDisplay } from "~/contexts/sdContext";
import { useClipCursorContext } from "~/contexts/clipCursorContext";
import { useEventListener } from "usehooks-ts";
import {
  dispatchFocusButtonEvent,
  dispatchResetInputEvent,
} from "~/customEvents";
import { toggleActiveScrollButton } from "~/redux/slices/starsTempSlice";

const fill = new Uint8Array([0, 0x24, 0]);
const selectedFill = new Uint8Array([0, 0x4e, 0]);
const buttonBorderFill = new Uint8Array([0x80, 0x80, 0x80]);
const focusedTint = new Uint8Array([0xff, 0xff, 0x99]);
const tint = new Uint8Array([0xc0, 0xc0, 0xc0]);

const dimMultiplier = 0.5;

type DcbButtonProps = {
  buttonId: ButtonId;
  x: number;
  y: number;
  width?: number;
  height?: number;
  disabled?: boolean;
  zIndex?: number;
  nextButton?: ButtonId;
  prevButton?: ButtonId;
};
const Button = ({
  buttonId,
  nextButton,
  prevButton,
  x = 0,
  y = 0,
  width = dcbButtonWidth,
  height = dcbHeight,
  disabled: _disabled = false,
  zIndex = 1,
  selectedSelector,
  textSelector,
  onmousedown,
  onclick,
  onwheel,
}: DcbButtonProps & Partial<ButtonMapValue>) => {
  const disabled = dcbButtonPropsMap[buttonId]?.disabled || _disabled;
  const bright = useRootSelector((state) =>
    selectBrightButtonValue(state, "BRITE_DCB")
  );
  const fontNum = useRootSelector((state) =>
    selectCharSizeButtonValue(state, "CHAR_SIZE_DCB")
  );
  const dispatch = useRootDispatch();
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref, disabled);
  const _selected = useRootSelector((state) => (selectedSelector ?? actualSelectButtonSelected)(state, buttonId, !!onwheel));
  const lines = useRootSelector((state) => textSelector?.(state)) ??
    buttonId.split("_");
  const [tempSelected, setTempSelected] = useState(false);
  const { setClipButtonRectangle, setCursor } = useClipCursorContext();
  const selected = _selected || tempSelected;
  const wasSelectedRef = useRef(false);

  useEventListener(
    "keydown",
    (e) => {
      if (focused && e.key === "Enter" && !onwheel) {
        e.stopImmediatePropagation();
        dispatchResetInputEvent();
        if (onclick) {
          dispatch(onclick());
        } else {
          dispatch(toggleButtonSelected(buttonId));
        }
      }
      if (focused && (!onwheel || !selected)) {
        switch (e.code) {
          case "Space":
            if (nextButton) {
              dispatchFocusButtonEvent(nextButton);
            }
            break;
          case "Backspace":
            if (prevButton) {
              dispatchFocusButtonEvent(prevButton);
            }
            break;
        }
      }
    },
    undefined,
    { capture: true, passive: true },
  );

  useLayoutEffect(() => {
    if (window.__TAURI__ && ref.current) {
      const rect = ref.current.getBounds();
      if (!disabled && _selected && !!onwheel) {
        wasSelectedRef.current = true;
        setClipButtonRectangle(rect);
        setCursor(null);
      } else if (wasSelectedRef.current) {
        wasSelectedRef.current = false;
        setClipButtonRectangle(null);
        setCursor("pointer");
      }
    }
  }, [disabled, _selected, !!onwheel]);

  useCustomEventListener<ButtonId>("focusButton", (e) => {
    if (e.detail === buttonId) {
      if (ref.current) {
        const rect = ref.current.getBounds();
        void setCursorPosition(rect.x + rect.width / 2, rect.y + rect.height / 2);
      }
    }
  });

  const fontName = starsFontNameMap[fontNum];
  const fontSize = starsFontDimMap[fontNum];

  const alpha = bright / 100;
  const textTop = (height - lines.length * fontSize.height) / 2;
  const textTint = computeColor(
    focused ? focusedTint : tint,
    (disabled ? dimMultiplier : 1) * alpha,
    0,
  );

  return (
    <Container x={x} y={y} zIndex={zIndex}>
      <Graphics
        ref={ref}
        hitArea={new Rectangle(0, 0, width, height)}
        eventMode="static"
        draw={(graphics) => {
          graphics.clear();
          const fillColor = computeColor(
            selected ? selectedFill : fill,
            (disabled ? dimMultiplier : 1) * alpha,
          );
          const borderFillColor = computeColor(
            buttonBorderFill,
            (disabled ? dimMultiplier : 1) * alpha,
          );
          graphics.lineStyle(1, 0);
          graphics.beginFill(fillColor)
            .drawRect(1, 1, width - 2, height - 2)
            .endFill();
          graphics.lineStyle();
          graphics.beginFill(selected ? 0 : borderFillColor)
            .drawPolygon(
              0,
              height,
              3,
              height - 3,
              3,
              3,
              width - 3,
              3,
              width,
              0,
              0,
              0,
              0,
              height,
            )
            .endFill();
          graphics.beginFill(!selected ? 0 : borderFillColor)
            .drawPolygon(
              width - 1,
              0,
              width - 1,
              height - 1,
              0,
              height - 1,
              3,
              height - 4,
              width - 4,
              height - 4,
              width - 4,
              3,
              width - 1,
              0,
            )
            .endFill();
        }}
        onmouseenter={() => {
          if (!disabled && _selected && !!onwheel) {
            setCursor(null);
          }
        }}
        onmouseleave={() => setTempSelected(false)}
        onmousedown={(e) => {
          if (!disabled) {
            setTempSelected(true);
            if (onmousedown) {
              dispatch(onmousedown(e));
            }
          }
        }}
        onmouseup={() => {
          setTempSelected(false);
          if (!disabled && tempSelected) {
            if (onclick) {
              dispatch(onclick());
            } else {
              dispatch(!!onwheel ? toggleActiveScrollButton(buttonId) : toggleButtonSelected(buttonId));
            }
          }
        }}
        onwheel={(e) => {
          if (onwheel && selected) {
            dispatch(onwheel(e));
          }
        }}
      />
      {lines.map((t, i) => {
        return (
          <BitmapText
            key={i}
            eventMode="none"
            anchor={[0.5, 0]}
            x={Math.floor(width / 2)}
            y={1 + textTop + i * fontSize.height}
            text={t}
            tint={textTint}
            fontName={fontName}
            style={{ fontName, tint: textTint }}
          />
        );
      })}
    </Container>
  );
};

const DcbRangeButton = (props: DcbButtonProps) => {
  const { range } = useSituationDisplay();
  return <Button {...props} textSelector={() => ["RANGE", range.toString()]} />;
};

const dcbButtonComponentMap: Partial<
  Record<ButtonId, ComponentType<DcbButtonProps>>
> = {
  RANGE: DcbRangeButton,
};

export const DcbButton = (props: DcbButtonProps) => {
  const Component = dcbButtonComponentMap[props.buttonId] ?? Button;
  const extraProps = dcbButtonPropsMap[props.buttonId] ?? {};
  return <Component {...props} {...extraProps} />;
};
