import { colorNameMap } from "@poscon/shared-frontend";
import { compassDirections } from "@poscon/shared-types";

const orange = new Uint8Array([255, 55, 0]);
const dimGrey = new Uint8Array([140, 140, 140]);
const darkGreyBlue = new Uint8Array([38, 77, 77]);
const darkMustard = new Uint8Array([100, 100, 51]);

export const tcwColors = {
  // datablock colors
  fdbUnowned: colorNameMap.green,
  fdbUnownedBlink: colorNameMap.green,
  fdbAlertRed: colorNameMap.red,
  recvHandoffAttn: colorNameMap.white,
  rvsmIneligNonFdbBlink: colorNameMap.lightCyan,
  recvPointoutAttn: colorNameMap.yellow,
  recvPointoutAccepted: colorNameMap.yellow,
  rvsmIneligNonFdb: colorNameMap.lightCyan,
  nonFdbUnowned: colorNameMap.green,
  nonFdbUnownedBlink: colorNameMap.green,
  ghostDb: colorNameMap.yellow,
  fusedTrackSymbol: new Uint8Array([30, 120, 255]),
  beaconTargetExtent: colorNameMap.green,
  history1: new Uint8Array([30, 80, 200]),
  history2: new Uint8Array([70, 70, 170]),
  history3: new Uint8Array([50, 50, 130]),
  history4: new Uint8Array([40, 40, 110]),
  // used for history 5-10
  history5: new Uint8Array([30, 30, 90]),
  predictedTrackLine: colorNameMap.white,
  ownedPositionSymbol: colorNameMap.white,
  testTarget: colorNameMap.white,
  unownedFdbPosSymbOutline: colorNameMap.black,
  fdbPosSymbOutline: colorNameMap.black,
  nonFdbPosSymbOutline: colorNameMap.black,
  fdbCautionActiveBlink: colorNameMap.yellow,
  ownedDb: colorNameMap.white,
  ownedDbBlink: colorNameMap.white,
  fdbHighlightMultiTrk: colorNameMap.lightCyan,
  fdbHighlightMultiTrkBlink: colorNameMap.lightCyan,
  fdbPosSymbHighlight: colorNameMap.lightCyan,
  fdbPosSymbHighlightBlink: colorNameMap.lightCyan,
  unownedFdbRvsmInelig: colorNameMap.lightCyan,
  unownedFdbRvsmIneligBlink: colorNameMap.lightCyan,
  ownedPosSymbBlink: colorNameMap.white,
  unownedFdbCautionActive: colorNameMap.yellow,
  posSymbHandoffAttnBlink: colorNameMap.white,
  posSymbPointoutAttnBlink: colorNameMap.yellow,
  posSymbPointoutAccepted: colorNameMap.yellow,
  fdbCautionAck: colorNameMap.yellow,
  nonFdbCautionAck: colorNameMap.yellow,
  onFdbCautionActiveBlink: colorNameMap.yellow,
  // terminal proximity alert
  tpa: new Uint8Array([90, 180, 255]),
  atpaCaution: colorNameMap.yellow,
  atpaAlert: orange,
  fdbAtpaAlert: orange,
  fdbAtpaAlertBlink: orange,
  unownedFdbAtpaAlert: orange,
  unownedFdbAtpaAlertBlink: orange,
  unownedFdbCautionAck: colorNameMap.yellow,

  // other map related colors
  rangeBearingLine: colorNameMap.white,
  catAMaps: dimGrey,
  catBMaps: dimGrey,
  compassRose: dimGrey,
  rangeRings: dimGrey,
  geoBorderFill: dimGrey,

  // wx
  wxLvl123: darkGreyBlue,
  wxLvl456: darkMustard,
  wxPattern: colorNameMap.white,
};
